<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="历史刊发" name="first">
        <div v-show="tab == 0">
          <el-form class="searchForm"
            ><div class="search">
              <div class="searchContainer">
                <el-select
                  v-model="input"
                  class="addInput"
                  placeholder="请选择查询条件"
                  clearable
                  @change="inputVal"
                >
                  <el-option
                    v-for="item in dataList"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="searchContainer" v-show="this.value == 0">
                <el-input
                  class="mySearch"
                  placeholder="请输入作者名字"
                  v-model="editor"
                ></el-input>
              </div>

              <div class="searchContainer1" v-show="this.value == 1">
                <el-form-item class="mySearchTwo">
                  <el-date-picker
                    class="mySearchHalf"
                    style="width: 170px"
                    type="date"
                    placeholder="请选择开始时间"
                    value-format="yyyy-MM-dd "
                    v-model="starttime"
                  ></el-date-picker>
                  <span class="to">至</span>
                  <el-date-picker
                    class="mySearchHalf"
                    type="date"
                    placeholder="请选择结束时间"
                    style="width: 170px"
                    v-model="endtime"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </div>

              <div class="searchContainer1" v-show="this.value == 2">
                <el-form-item class="mySearchTwo">
                  <el-date-picker
                    class="mySearchHalf"
                    type="date"
                    placeholder="请选择开始时间"
                    v-model="starttime"
                    style="width: 170px"
                    value-format="yyyy-MM-dd "
                  ></el-date-picker>
                  <span class="to">至</span>
                  <el-date-picker
                    class="mySearchHalf"
                    type="date"
                    v-model="endtime"
                    placeholder="请选择结束时间"
                    style="width: 170px"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </div>
            <div class="searchContainerBtn">
              <el-button type="danger" @click="ordersCount()">查询</el-button>
            </div>
          </el-form>

          <div style="margin-top: 27px">
            <div class="title">
              <div class="container_table">
                <el-table
                  :header-cell-style="headeRowClass"
                  :data="tableData"
                  stripe
                  style="width: 100%"
                  :default-sort="{ prop: 'date', order: 'descending' }"
                >
                  <el-table-column prop="title" label="文章标题" sortable>
                  </el-table-column>
                  <el-table-column prop="editor" label="作者" sortable>
                  </el-table-column>
                  <el-table-column prop="fwsname" label="服务商" sortable>
                  </el-table-column>
                  <el-table-column
                    prop="Contribution_time"
                    label="投稿时间"
                    sortable
                  >
                  </el-table-column>
                  <el-table-column
                    prop="Publication_time"
                    label="刊发时间"
                    sortable
                  >
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <span
                        class="editText"
                        style="margin-right: 50px; cursor: pointer"
                        @click="TixianTap(scope.row)"
                        >查看</span
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <div
                  class="ygdPage"
                  style="
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    margin-top: 20px;
                  "
                >
                  <el-pagination
                    class="fy"
                    small
                    @size-change="handleSizeChange"
                    :page-size="pagesize"
                    :page-sizes="[10, 20, 30]"
                    layout="total, sizes, prev, pager, next, jumper"
                    @current-change="current_change"
                    :total="total"
                    background
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="tab == 1"></div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { historydata } from "../../../API/QKD/history.js"
export default {
  components: {

  },
  data () {
    return {
      activeName: 'first', //默认选项卡初始化1
      value: '',//订单跟踪具体内容（投稿，刊发）
      input: '', // 下拉
      editor: '', // 作者
      starttime: '', // 开始时间
      endtime: '', // 结束时间
      loading: false,
      tab: 0,//0 列表   1提现页面
      total: 0, //默认数据总数
      pagesize: 10, //每页的数据条数
      currentPage: 1, //默认开始页面

      tableData: [], //列表
      dialog: false,
      id: '',
      dataList: [{ val: '0', name: '按作者查询' }, { val: '1', name: '按投稿时间查询' }, { val: '2', name: '按刊发时间查询' },]
    };
  },
  mounted () {
    this.ordersCount()
  },
  methods: {
    aa () {
      this.tab = 0
      this.ordersCount()
    },
    inputVal (e) {
      this.starttime = '', // 开始时间
        this.endtime = '', // 结束时间
        console.log(e);
      this.value = e
      if (e !== 0) {
        this.editor = null;
      }
    },
    // 更改表头样式
    headeRowClass ({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #F2F2F2;color: #666666;font-weight: 700;'
      }
    },
    TixianTap (row) {
      this.$router.push({ path: "/Periodical/history/detail", query: { "wid": row.id, 'id': 10 } })

    },
    // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.ordersCount()
    }, handleSizeChange (val) {

      this.pagesize = val;
      this.ordersCount()
    },
    handleClick (tab, event) {
      console.log(tab, event);
    },
    ordersCount () {
      let id = localStorage.getItem('QKId')
      historydata(id, this.currentPage, this.pagesize, this.editor, this.starttime, this.endtime, this.value).then(res => {
        this.tableData = res.data.data
        console.log(this.tableData)
        this.total = res.data.total
      })
    }
  },
};
</script>

<style>
.left,
.right {
  width: 98%;
  height: auto;
}

.left_title {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.left_title span {
  margin-right: 10px;
}

.allocate {
  width: 100px;
  height: 40px;
  background: #ff1918;
  border-radius: 4px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  margin-left: 20px;
}

.orderPage {
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchContainer {
  width: 200px;
  margin-right: 20px;
}
.search {
  width: 100%;
  height: 50px;
  display: flex;
}
</style>