//statistic.js
import request from '@/utils/request'
import qs from 'qs'

// 历史刊发数据
export function historydata (id,page,limit,editor,starttime,endtime,operation) {
  let data = {
  id,page,limit,editor,starttime,endtime,operation
  }
  data = qs.stringify(data)
  return request({
    url: '/qikan/historykanfa',
    method: 'post',
    data
  })
}
